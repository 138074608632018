import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'adult', 'u15', 'adultBYOG', 'u15BYOG', 'family', 'button', 'disable' ]
  static values = { stripe: String }
  
  originalTextContent = null;
  buying = false;
  connect() {
    this.originalTextContent = this.buttonTarget.textContent;
  }

  values () {
    return { 
      adult: { qty: parseInt(this.adultTarget.value), cents: parseInt(this.adultTarget.getAttribute('data-price-cents')) },
      u15: { qty: parseInt(this.u15Target.value), cents: parseInt(this.u15Target.getAttribute('data-price-cents')) },
      adultBYOG: { qty: parseInt(this.adultBYOGTarget.value), cents: parseInt(this.adultBYOGTarget.getAttribute('data-price-cents')) },
      u15BYOG: { qty: parseInt(this.u15BYOGTarget.value), cents: parseInt(this.u15BYOGTarget.getAttribute('data-price-cents')) },
      family: { qty: parseInt(this.familyTarget.value), cents: parseInt(this.familyTarget.getAttribute('data-price-cents')) }
    }

  }

  calc() {
    if (this.buying != false) {
      return;
    }
    const totalCostCents = Object.values(this.values()).map((m) => m.cents * m.qty).reduce((a,b) => a+b, 0);
    if (totalCostCents > 0) {
      this.buttonTarget.innerHTML = `Buy ($${(totalCostCents/100).toFixed(2)})`;
      this.buttonTarget.style.opacity = '1';
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.innerHTML = `Buy`;
      this.buttonTarget.disabled = true;
      this.buttonTarget.style.opacity = '0.5';
    }
  }

  buy() {
    this.setButtonEnabled(false);
    this.disableTargets.forEach((b) => { b.disabled = true; b.style.opacity = '0.5'; });
    let stripe = Stripe(this.stripeValue)
    this.createSession(this.planValue).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe.redirectToCheckout({
        sessionId: data.session_id
      }).catch((err) => {
        this.disableTargets.forEach((b) => { b.disabled = false; b.style.opacity = '1'; });
        this.buying = false;
        alert(`Error redirecting to checkout session: ${err}`)
        this.setButtonEnabled(true);
      });

    }).catch((err) => {
      this.buying = false;
      this.disableTargets.forEach((b) => { b.disabled = false; b.style.opacity = '1'; });
      alert(`Error creating checkout session: ${err}`)
      this.setButtonEnabled(true);
    });
  }

  setButtonEnabled(enabled) {
    if (enabled) {
      this.buttonTarget.disabled = false;
      this.buttonTarget.style.opacity = '1';
      this.buttonTarget.textContent = this.originalTextContent;
    } else {
      if (!this.buttonTarget.disabled) {
        this.originalTextContent = this.buttonTarget.textContent;
      }
      this.buttonTarget.disabled = true;
      this.buttonTarget.style.opacity = '0.5';
      this.buttonTarget.textContent = "Loading..."
    }
  }

  createSession(plan) {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    return fetch("./", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        type: "checkoutv2",
        values: this.values()
      })
    }).then(function(result) {
      return result.json();
    })
  }
}
