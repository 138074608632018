import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button']
  static values = { plan: String, stripe: String}
  
  originalTextContent = null;
  connect() {
    this.originalTextContent = this.buttonTarget.textContent;
  }

  buy() {
    this.setButtonEnabled(false);
    let stripe = Stripe(this.stripeValue)
    this.createSession(this.planValue).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      stripe.redirectToCheckout({
        sessionId: data.session_id
      }).catch((err) => {
        alert(`Error redirecting to checkout session: ${err}`)
        this.setButtonEnabled(true);
      });

    }).catch((err) => {
      alert(`Error creating checkout session: ${err}`)
      this.setButtonEnabled(true);
    });
  }

  setButtonEnabled(enabled) {
    if (enabled) {
      this.buttonTarget.diabled = false;
      this.buttonTarget.style.opacity = '1';
      this.buttonTarget.textContent = this.originalTextContent;
    } else {
      this.buttonTarget.disabled = true;
      this.buttonTarget.style.opacity = '0.5';
      this.buttonTarget.textContent = "Loading..."
    }
  }

  createSession(plan) {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    return fetch("./", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        plan: plan
      })
    }).then(function(result) {
      return result.json();
    })
  }
}
