import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

import { BrowserQRCodeReader, NotFoundException} from '@zxing/library';


export default class extends Controller {
    static targets = ['video','error','errorMessage']
    static values = { plan: String, stripe: String }
    connect() {


        this.codeReader = new BrowserQRCodeReader()
                this.errorTarget.className = 'hidden'

        this.codeReader.decodeOnceFromVideoDevice(null, this.videoTarget)
        .then(function(result) { 
            window.location.href = result
        }).catch((err) => {
            if (!(err instanceof NotFoundException)) {
                this.errorTarget.className = ''
                this.errorMessageTarget.innerHTML = String(err)
            }
        })


        // this.buttonTarget.textContent = "Hello World!"
    }
    disconnect() {
        this.codeReader.stopAsyncDecode();
        this.codeReader.reset();
        this.codeReader = null;
    }

    //   buy() {
    //     this.buttonTarget.disabled = true;
    //     this.buttonTarget.style.opacity = '0.5';
    //     let stripe = Stripe(this.stripeValue)
    //     this.createSession(this.planValue).then(function(data) {
    //       // Call Stripe.js method to redirect to the new Checkout page
    //       stripe.redirectToCheckout({
    //           sessionId: data.session_id
    //         })
    //         .then(handleResult);
    //       });
    //   }

}
