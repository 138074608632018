import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context(".", true, /\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)


// import and register flatpickr with styles
import Flatpickr from 'stimulus-flatpickr'
require("flatpickr/dist/themes/airbnb.css")
application.register('flatpickr', Flatpickr)
