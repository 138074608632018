import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['field', 'add', 'remove']
  static values = { min: Number, max: Number}
  
  connect() {
    this.makeValidAndEnableButtons();
  }

  value() {
    return parseInt(this.fieldTarget.value);
  }

  down() {
    this.fieldTarget.value = this.value() - 1;
    this.makeValidAndEnableButtons();
  }

  up() {
    this.fieldTarget.value = this.value() + 1;
    this.makeValidAndEnableButtons();
  }

  makeValidAndEnableButtons() {
    const v = this.value();
    if (v <= this.minValue) {
      this.fieldTarget.value = this.minValue;
      this.setEnabled(this.removeTarget, false);
    } else if (v >= this.maxValue) {
      this.fieldTarget.value = this.maxValue;
      this.setEnabled(this.addTarget, false);
    } else {
      this.setEnabled(this.addTarget, true);
      this.setEnabled(this.removeTarget, true);
    }
  }

  setEnabled(button, enabled) {
    if (enabled) {
      button.disabled = false;
      button.style.opacity = '1';
    } else {
      button.disabled = true;
      button.style.opacity = '0.5';
    }
  }

}
